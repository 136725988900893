@import 'assets/scss/mixins';

.configurator {
    padding: 32px 24px;
    //overflow-y: hidden;
    height: 100%;
    overflow: auto;
    // z-index: 12;
    @include scrollbar(4px, $dark80, transparent);
    position: relative;

    &.overflow_hidden {
        overflow-y: hidden;
    }

    &.smaller {
        padding: 16px;
    }

    &.isCheckout {
        padding: 0;
        .preconig_wrapper {
            min-height: 100%;
        }
    }
}

.sidebar_main {
    display: flex;
    gap: 8px;
    margin-bottom: 8px;
    transition: flex-wrap 300ms ease-in;
    flex-direction: column;
    flex-wrap: unset;

    &.wrap {
        flex-wrap: wrap;
        // flex-direction: row;
    }
}

.preconig_wrapper {
    position: relative;
    display: flex;
    flex-direction: column;
}

.summary {
    padding: 6px 12px;
    border: 1px solid $dark80;
    background-color: $darkClick;
    border-radius: 20px;
    display: flex;
    align-items: center;
    cursor: pointer;
    color: $dark20;
    z-index: 15;
    position: absolute;
    top: unset;
    bottom: 195px;
    &.discount {
        bottom: 355px;

        &.zero {
            bottom: 320px;

            &.tradeIn {
                bottom: 340px;
            }
        }
    }
    &.discount:not(.zero) {
        bottom: 365px;

        &.tradeIn {
            bottom: 385px;
        }
    }
    left: 50%;
    transform: translate(-50%, -50%);
    animation: fadeIn 500ms forwards;
    width: max-content;

    h6 {
        line-height: 24px;
    }

    .icon {
        transform: rotate(180deg);
    }

    &.hide {
        display: none;
        opacity: 0;
    }
}

@keyframes fadeIn {
    from {
        opacity: 0;
    }
    to {
        opacity: 1;
    }
}

.skeleton {
    display: flex;
    gap: 4px;
    margin-top: 50px;
    flex-wrap: wrap;
}

.configurator_footer {
    padding: 38px 24px 24px 32px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: flex-start;
    color: $mainWhite;
    border-top: 1px dashed $dark80;
    position: relative;
    z-index: 14;
    background-color: $mainBackground;

    &.smaller {
        padding: 16px;
    }

    &::before {
        content: '';
        position: absolute;
        top: -6px;
        left: -7px;
        background-color: $dark20;
        width: 12px;
        height: 12px;
        border-radius: 50%;
    }

    &::after {
        content: '';
        position: absolute;
        top: -6px;
        right: -7px;
        background-color: $dark20;
        width: 12px;
        height: 12px;
        border-radius: 50%;
        z-index: 12;
    }

    .button {
        width: 100%;
        margin-top: 20px;
        max-height: 64px;
        padding: 22px 0;

        .btn_loader {
            margin-left: 10px;

            &:after {
                width: toRem(22);
                height: toRem(22);
            }
        }
    }

    .price_from_wrapper {
        display: flex;
        margin-bottom: 2px;

        .question_mark {
            margin-left: 4px;
            font-size: 18px;
            cursor: pointer;
        }
    }
}

.per_month {
    font: 500 14px/24px Mulish, san-serif;
    color: $dark20;
    margin-left: 4px;
}

.total_price {
    font-size: 14px;
    line-height: 20px;
    color: #ffffff60;
}

.price {
    color: $dark5;
    display: flex;
    align-items: flex-end;
}

.bikeSizes {
    :global(.tag) {
        width: 40px;
    }
}

.include_taxes {
    color: $dark20;
    width: 100%;
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-top: 12px;

    h3 {
        font-size: 14px;
        font-family: $secondary-font;
        font-weight: 600;
    }
}

//.summary_drawer {
//    height: 100%;
//}

.loaderWrap {
    display: flex;
    align-items: flex-end;
    margin-left: 5px;
}
.loader,
.loader::after {
    height: 15px;
    width: 15px;
    border-color: #ffffff60 transparent #ffffff60 transparent;
}

.discountSection {
    display: flex;
    flex-direction: column;
    width: 100%;
    color: $dark20;

    .line {
        display: flex;
        align-items: center;
        justify-content: space-between;
    }
}

.actions {
    display: flex;
    align-items: center;
    gap: 16px;
}

.discountButton {
    font-size: 12px !important;
    width: 100%;
    margin-top: 20px;
    max-height: 64px;
    padding: 10px 0;
    text-transform: none;

    .btn_loader {
        margin-left: 10px;

        &:after {
            width: toRem(22);
            height: toRem(22);
        }
    }
}

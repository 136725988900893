@import 'assets/scss/mixins';

.root {
    margin-top: 24px;
}

.inputWrapper {
    display: flex;
    flex-direction: column;
    gap: 12px;
}

.button {
    margin-top: 40px;
    width: 100%;

    @include screen('mobile') {
        margin-top: 24px;
    }
}
.error {
    text-align: end;
}
.label {
    label {
        color: $dark60;
    }
}

@import 'assets/scss/mixins';

.mobile_tablet_sidebar {
    .container {
        display: flex;
        flex-direction: column;
        height: 220px;
        &.big {
            height: 280px;
        }
        overflow: hidden;

        @include screen('mobile') {
            height: 172px;
        }
    }
}

.mobile_tablet_sidebar {
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;

    .spinner {
        width: 100%;
        height: 100%;
        display: flex;
        justify-content: center;
        align-items: center;
    }

    .initial_container {
        //height: 110px;
        display: flex;
        justify-content: center;
        align-items: center;
        gap: 32px;

        @include screen('mobile') {
            gap: 8px;
            height: 100%;
        }

        > div {
            flex-shrink: 0;
        }
    }

    .price_container {
        //padding: 22px 32px 24px;
        // height: 110px;
        display: flex;
        justify-content: center;
        align-items: center;
        flex: 1;
        overflow: auto;
        @include scrollbar(3px, $dark80, transparent);

        @include screen('mobile') {
            padding: 0 16px;
            // height: 124px;
        }
    }

    .smallDivider {
        width: 1px;
        height: 20px;
        background-color: $dark80;
        display: block;
        flex-shrink: 0;
        margin: 0 12px;
    }

    .buttonContainer {
        padding: 20px 32px 24px;
        // height: 110px;
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        border-top: 1px dashed $dark80;
        position: relative;

        @include screen('mobile') {
            padding: 16px;
        }

        &::before {
            content: '';
            position: absolute;
            top: -6px;
            left: -7px;
            background-color: $dark20;
            width: 12px;
            height: 12px;
            border-radius: 50%;
        }

        &::after {
            content: '';
            position: absolute;
            top: -6px;
            right: -7px;
            background-color: $dark20;
            width: 12px;
            height: 12px;
            border-radius: 50%;
            z-index: 12;
        }

        .per_month {
            font: 500 14px/24px Mulish, san-serif;
            color: $dark20;
            margin-left: 4px;
        }

        .price_wrapper {
            display: flex;
            justify-content: space-between;
        }
    }

    .includes_tax {
        display: flex;
        justify-content: space-between;
        color: $dark20;
        margin-top: 8px;
    }

    .color {
        height: 64px;
        width: 64px;
        border-radius: 50%;
        position: relative;

        @include screen('mobile') {
            width: 48px;
            height: 48px;
        }

        &.rainbow_img {
            background-image: url('/assets/images/color-picker.png');
            background-repeat: no-repeat;
            background-position: center;
            background-size: cover;
        }
    }

    .item {
        padding: 0 32px;
        min-width: 64px;
        height: 64px;
        display: flex;
        justify-content: center;
        align-items: center;
        background-color: $dark1;
        border-radius: 40px;
        color: $dark5;
        position: relative;
        text-transform: capitalize;

        @include screen('mobile') {
            min-width: 48px;
            height: 48px;
            padding: 0 12px;

            h5 {
                font-size: toRem(14);
                font-family: $secondary-font;
            }

            h6 {
                font-size: toRem(12);
            }
        }

        h6 {
            z-index: 2;
        }

        img {
            position: absolute;
            width: 96px;
            height: 54px;
            z-index: 1;
            filter: blur(3px);

            @include screen('mobile') {
                width: 88px;
                height: 40px;
            }
        }
    }

    .absolute_item {
        width: 24px;
        height: 24px;
        position: absolute;
        border-radius: 50%;
        bottom: 0;
        right: 0;
        background-color: $darkClick;
        display: flex;
        justify-content: center;
        align-items: center;
        padding: 3px;
        border: 2px solid $dark2;

        i {
            color: $mainWhite;
            font-size: 18px;
        }

        h6 {
            color: $dark5;
        }
    }

    .total_price {
        flex-shrink: 0;

        h6 {
            color: $dark40;
        }

        h2 {
            color: $dark5;

            @include screen('mobile') {
                font-size: toRem(22);
            }
        }

        .your_price_wrapper {
            display: flex;

            .question_mark {
                color: $dark20;
                font-size: 18px;
                margin-left: 4px;
            }
        }

        .show_summary {
            display: flex;

            h6 {
                color: $dark20;
                text-decoration: underline;
            }

            .icon {
                color: $dark5;
            }

            &.hide {
                display: none;
            }
        }
    }

    .button {
        width: 314px;
        padding: 22px;
        // margin: auto 16px;
        height: 60px;

        i {
            margin-right: 12px;
        }

        @include screen('mobile') {
            width: 147px;
            height: 64px;
        }

        .btn_loader {
            margin-left: 10px;

            &:after {
                width: toRem(22);
                height: toRem(22);
            }
        }
    }
}

@media screen and (max-height: 499px) and (orientation: landscape) {
    .mobile_tablet_sidebar {
        display: none;
    }
}

.discountSection {
    display: flex;
    flex-direction: column;
    width: 100%;
    color: $dark20;

    .line {
        display: flex;
        align-items: center;
        justify-content: space-between;
    }
}

.actions {
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 8px;
    margin-top: 8px;
    .row {
        width: 100%;
        display: flex;
        align-items: center;
        gap: 16px;
    }
}

.discountButton {
    font-size: 11px !important;
    width: 100%;
    max-height: 64px;
    padding: 8px 0;

    .btn_loader {
        margin-left: 10px;

        &:after {
            width: toRem(22);
            height: toRem(22);
        }
    }
}

.summary {
    @include screen('large-tablet') {
        &.zero {
            bottom: 220px;

            &.tradeIn {
                bottom: 240px;
            }
        }
    }
}
.summary:not(.zero) {
    @include screen('large-tablet') {
        bottom: 263px;

        &.tradeIn {
            bottom: 273px;
        }
    }
}

@import 'assets/scss/mixins';

.part_variant {
    display: flex;
    padding: 16px;
    gap: 12px;
    background-color: $dark5;
    border-radius: 4px;
    min-height: 162px;
    position: relative;
    border: 2px solid transparent;
    transition: background-color 200ms ease-in;
    cursor: pointer;

    &:hover {
        background-color: $dark10;
    }

    .image {
        width: 130px;
        height: 130px;
        align-self: flex-start;
        position: relative;
        transition: all 300ms ease-in-out;

        img {
            height: 100%;
            width: 100%;
            object-fit: contain;
        }

        .expand {
            position: absolute;
            top: 0;
            left: 0;
            width: 27px;
            height: 27px;
            border-radius: 50%;
            display: flex;
            justify-content: center;
            align-items: center;
            background-color: $dark10;

            i {
                font-size: 18px;
                color: $dark40;
            }
        }
        &:hover {
            transform: scale(1.15);
        }
    }

    .info {
        display: flex;
        justify-content: space-between;
        flex-direction: column;
        flex: 1;
    }

    .price_container {
        display: flex;
        gap: 8px;

        .price_with_discount {
            color: $mainAttention;
        }

        .discount {
            text-decoration: line-through;
            color: $dark40;
        }
    }

    .variant_name {
        color: $dark1;
        max-width: 77%;
        margin-bottom: 4px;
    }

    .price {
        color: $dark60;
    }

    .description {
        @extend .price;
        @include line-clamp(2);
        margin: 12px 0 8px;
    }

    .check_mark {
        width: 24px;
        height: 24px;
        position: absolute;
        top: 12px;
        right: 12px;
        background-color: $mainRed;
        border-radius: 50%;
        display: flex;
        justify-content: center;
        align-items: center;

        i {
            color: $mainWhite;
            font-size: 20px;
        }
    }

    @include screen('large-tablet') {
        min-width: 343px;
        min-height: 272px;
        flex-direction: column;
        margin-bottom: 16px;
        justify-content: space-between;

        .image {
            width: 80px;
            height: 80px;
        }

        .variant_name {
            color: $dark1;
            max-width: 100%;
            margin-bottom: 4px;
        }

        .variant_desc {
            p {
                @include line-clamp(3);
            }
        }
    }

    @include screen('mobile') {
        width: 100%;
        min-height: auto;
        flex-direction: row;
        gap: 8px;
        margin-bottom: 0;

        .info {
            width: 100%;
            height: fit-content;
        }

        .variant_name {
            max-width: 200px;
        }

        .image {
            width: 64px;
            height: 64px;
        }

        .variant_desc {
            margin: 12px 0 8px;

            p {
                @include line-clamp(4);
            }
        }

        .add_remove {
            top: 16px;
            right: 16px;
        }
    }
}

.add_remove {
    position: absolute;
    top: 12px;
    right: 12px;
    font-family: $primary-font;
    color: $mainRed;
    letter-spacing: 0.04em;
    font-style: normal;
    font-weight: 500;
    font-size: 14px;
    line-height: 20px;

    &.remove_btn {
        color: $dark60;
    }
}

.variant_desc {
    p {
        font-family: $secondary-font;
        font-size: toRem(14) !important;
        line-height: toRem(22);
        color: $dark60;
        @include line-clamp(2);
        justify-self: flex-end;
    }
}

.weight_container {
    width: 100%;
    display: flex;
    // justify-content: space-between;
    justify-content: flex-end;
    align-items: center;

    .weight {
        display: flex;
        gap: 8px;
        color: $dark60;
    }

    .stock {
        font-size: toRem(12);
        color: $dark40;
    }
}

.active_variant {
    border: 2px solid $mainRed;
}

.zoom_image_container {
    z-index: 20;
    width: 350px;
    height: 350px;
    background-color: $mainWhite;
    padding: 24px;
    border-radius: 4px;
    box-shadow: 0 20px 40px rgba(24, 23, 25, 0.1);
    animation: smooth ease-in 200ms;

    i {
        position: absolute;
        font-size: 10px;
    }

    .top_left {
        top: 8px;
        left: 8px;
    }

    .top_right {
        top: 8px;
        right: 8px;
        transform: rotate(90deg);
    }

    .bottom_left {
        bottom: 8px;
        left: 8px;
        transform: rotate(270deg);
    }

    .bottom_right {
        bottom: 8px;
        right: 8px;
        transform: rotate(180deg);
    }
}

@keyframes smooth {
    0% {
        transform: scale(0.5);
    }
    100% {
        transform: scale(1);
    }
}

.loading_container {
    position: absolute;
    bottom: 42px;
    right: 32px;
}

.long_desc_modal {
    padding-top: 64px;
    display: flex;
    flex-direction: column;

    @include screen('mobile') {
        padding: 56px 16px 16px;
    }

    .image_wrapper {
        display: flex;
        align-items: center;
        justify-content: center;
        width: 100%;
        background-color: $dark3;
    }

    .long_desc_title {
        margin: 24px 0 16px;
        text-transform: uppercase;
    }

    .long_desc {
        letter-spacing: 0.01em;
        color: $dark60;
    }
    .modalContent {
        flex: 1;
        overflow: scroll;
    }
    button {
        width: 100%;
        margin: 10px 0;
    }
    .modalText {
        font-family: $secondary-font;
        font-weight: normal;
        margin: 10px 0;

        b {
            font-weight: bold;
        }
    }
    .modalAdd {
        color: $mainGreen;
    }
    .modalRemove {
        color: $mainRed;
        text-decoration: line-through;
    }
}

.newLoader {
    position: absolute;
    background: rgba(255, 255, 255, 0.75);
    color: $mainRed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    font-family: $secondary-font;
    cursor: wait;
    animation: pulse 1s ease-in-out infinite;
    backdrop-filter: blur(2px);
}

@keyframes pulse {
    0% {
        background: rgba(255, 255, 255, 0.65);
    }
    50% {
        background: rgba(255, 255, 255, 0.85);
    }
    100% {
        background: rgba(255, 255, 255, 0.65);
    }
}

@import 'assets/scss/mixins';
.form {
    padding: 24px 48px 48px;
    display: flex;
    flex-direction: column;
    gap: 16px;
    color: black;
    h4 {
        text-transform: uppercase;
        font-weight: 800;
    }
    p {
        color: $dark60;
    }
    .inputs {
        margin-top: 16px;
        display: flex;
        flex-direction: column;
        gap: 16px;

        > div {
            flex: 1;
            width: 100%;
            font-family: $secondary-font;
        }
    }
}
.labelWrapper {
    label {
        color: $dark1;
        display: block;
        font-size: toRem(16);
        line-height: toRem(24);
        text-transform: uppercase;
        font-family: $primary-font;
    }
}
.buttons {
    display: flex;
    justify-content: space-between;
}
.next {
    width: 100%;
    margin-top: 32px;
    padding: 16px 24px;
}
.error {
    color: $mainRed;
    font-size: 12px;
}

.responsePopup {
    h4 {
        color: black;
        font-size: 32px;
        font-weight: 500;
    }
    display: flex;
    flex-direction: column;
    gap: 32px;
}

.phoneInput {
    :global(.country-list) {
        max-height: 160px !important;
    }
}

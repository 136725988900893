.header {
    display: flex;
    justify-content: space-between;
    align-items: center;
}
.backButton {
    padding: 8px 20px;
}

@media print {
    .backButton {
        display: none;
    }
}

@import 'assets/scss/mixins';
.root {
    color: black;
    .product {
        padding: 6px 10px 6px 0;
        display: flex;
        justify-content: space-between;
        align-items: center;
        transition: background-color 0.3s ease-in-out, transform 0.3s ease-in-out, box-shadow 0.3s ease-in-out;
        cursor: pointer;
        img {
            width: 80px;
        }

        &:hover {
            background-color: rgba(0, 0, 0, 0.05);
            transform: scale(1.02);
            box-shadow: 0 4px 10px rgba(0, 0, 0, 0.1);
        }
    }
    .content {
        max-height: 240px;
        padding: 30px 10px;
        overflow-y: scroll;
    }
    .actions {
        margin-top: 4px;
        display: flex;
        justify-content: space-between;
        width: 100%;
    }
}

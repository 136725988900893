.circle {
    width: 14px;
    height: 14px;
    border-radius: 50%;
    cursor: pointer;
    &.small {
        width: 10px;
        height: 10px;
    }
}

.tooltip {
    max-height: 250px;
    min-width: 250px;
    overflow-y: scroll;
    padding: 8px 0;
    position: absolute;
    background-color: rgba(0, 0, 0, 0.75);
    color: #fff;
    border-radius: 4px;
    z-index: 1000;
    transition: opacity 0.3s ease;
}

.tooltipItem {
    display: flex;
    gap: 10px;
    justify-content: space-between;
    border-bottom: 1px solid #ccc;
    padding: 0 4px 0px;
    gap: 20px;

    .name {
        font-size: 12px;
    }

    .stock {
        color: gray;
        line-height: 12px;
        font-size: 12px;
        display: flex;
        gap: 12px;
        justify-content: space-between;
        align-items: center;
    }
    &:last-child {
        border: none;
    }
}

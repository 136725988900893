@import 'assets/scss/mixins';
.root {
    .backIcon {
        cursor: pointer;
        position: absolute;
        top: 24px;
        left: 24px;

        @include screen('mobile') {
            top: 16px;
            left: 16px;
        }
    }

    .body {
        color: black;
        display: flex;
        flex-direction: column;
        gap: 24px;

        @include screen('mobile') {
            padding: 24px 16px 32px;
        }

        h4 {
            color: black;
            text-transform: uppercase;
            font-weight: 500;
        }
    }

    .formLine {
        display: flex;
        align-items: center;
        justify-content: space-between;
        gap: 36px;
    }
    .input {
        max-width: 240px;
        width: 240px;
    }
}

@import 'src/assets/scss/mixins.scss';

.container {
    margin: 0 auto;
    max-width: 210mm;
    width: 100%;
    background-color: $mainWhite;
    padding: 20px;
    border: 1px solid $dark10;
    border-radius: 10px;

    p {
        font-size: 14px;
        line-height: 20px;
    }
}

.modal_overlay {
    width: 100vw;
    height: 100dvh;
    display: flex;
    align-items: center;
    justify-content: center;
    z-index: 900;
    position: fixed;
    transition: all 200ms ease-out;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    animation: darkenBG 300ms ease-in forwards;

    .modal {
        overflow: scroll;
        padding: 42px;
        position: relative;
        width: 100%;
        height: 100%;
        background-color: #ededed;
        border-radius: 4px;
        opacity: 0;
        transition: all 0.4s ease-in-out;
        animation: slideUp 300ms ease-in forwards;
        background-color: $dark3;
    }
}

@keyframes slideUp {
    from {
        transform: translate(0, 300px);
        opacity: 0;
    }
    to {
        transform: translate(0, 0);
        opacity: 1;
    }
}

@media print {
    .container {
        width: 100%;
        border: none;
    }
    .modal_overlay {
        height: unset;
    }
    .footer {
        display: none;
    }
}
@page {
    size: A4;
    margin: 0;
}

@import 'assets/scss/mixins';
.root {
    padding: 24px;
    max-height: calc(100vh - 80px);
    height: 100%;
    overflow-y: scroll;
    background-color: $dark3;
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    z-index: 11;

    form {
        min-height: 100%;
        display: flex;
        flex-direction: column;
    }
}

.action {
    display: flex;
    justify-content: space-between;
    align-items: center;
    max-width: 100%;
    flex-shrink: 0;

    overflow-x: scroll;
}

.labelWrapper {
    label {
        color: $dark1;
        display: block;
        font-size: toRem(16);
        line-height: toRem(24);
        text-transform: uppercase;
        font-family: $primary-font;
    }
}

.inputsWrapper {
    margin: 24px 0;
    display: flex;
    flex-direction: column;
    gap: 24px;
    flex: 1;
}
.row {
    display: flex;
    gap: 24px;

    .input {
        width: 100%;
    }
}

.clear {
    padding: 8px 16px;
    i {
        font-size: 18px !important;
    }
}

.title {
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.checkbox_container {
    display: flex;
    align-items: center;
    gap: 12px;
    margin-top: 12px;
    .checkbox_text {
        height: fit-content;
        color: $dark60;
        font: 14px/16px Mulish, sans-serif;
        margin: 0;
    }
}

// MODAL

.modal_overlay {
    width: 100vw;
    height: 100dvh;
    display: flex;
    align-items: center;
    justify-content: center;
    z-index: 900;
    position: fixed;
    transition: all 200ms ease-out;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    animation: darkenBG 300ms ease-in forwards;

    &.position {
        align-items: flex-end;
    }
}

.modal {
    padding: 48px;
    position: relative;
    width: 100%;
    max-height: 90dvh;
    height: auto;
    overflow: auto;
    background-color: $mainWhite;
    border-radius: 4px;
    opacity: 0;
    transition: all 0.4s ease-in-out;
    animation: slideUp 300ms ease-in forwards;

    &.dark {
        background-color: $mainBackground;
    }
}

.close_icon {
    cursor: pointer;
    z-index: 9;
    position: absolute;
    top: 24px;
    right: 24px;
    font-size: 24px !important;

    &.dark_icon {
        color: $dark5;
    }
}

@include screen('mobile') {
    .modal_overlay {
        align-items: flex-end;
    }
    .modal {
        padding: 16px 16px 32px;
        border-radius: 0;
    }
    .close_icon {
        top: 16px;
        right: 16px;
    }
}
//}

@keyframes slideUp {
    from {
        transform: translate(0, 300px);
        opacity: 0;
    }
    to {
        transform: translate(0, 0);
        opacity: 1;
    }
}
@keyframes darkenBG {
    from {
        background-color: rgba($mainBackground, 0);
    }
    to {
        background-color: rgba($mainBackground, 0.5);
    }
}

@import 'src/assets/scss/mixins.scss';

.header {
    flex: 0 0 80px;
    max-height: 80px;
    width: 100%;
    display: flex;
    background: $mainWhite;
    z-index: 20;

    @include screen('mobile') {
        flex: 0 0 56px;
        max-height: 56px;
    }
}

.logo {
    justify-self: flex-start;
}

.headerContent {
    flex: 1;
    display: flex;
    justify-content: flex-end;
    align-items: center;
    padding-right: 40px;
    border-bottom: 2px solid $dark5;

    @include screen('mobile') {
        padding-right: 16px;
    }
    @media screen and (max-width: 370px) {
        padding: 0 0 0 5px;
    }
}

.buttons {
    display: flex;
    gap: 32px;
    max-height: 35px;

    @include screen('mobile') {
        gap: 24px;
    }
    @media screen and (max-width: 370px) {
        padding-right: 5px;
        gap: 3px;
        width: 100%;
        justify-content: space-between;
    }
}
@media screen and (max-height: 499px) and (orientation: landscape) {
    .header {
        display: none;
    }
}

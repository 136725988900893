@import 'assets/scss/mixins';

.control {
    width: 40px;
    height: 40px;
    background-color: $dark5;
    border-radius: 50%;
    border: 1px solid $dark10;
    display: flex;
    align-items: center;
    justify-content: center;
    transition: all 150ms ease-in;

    @include screen('mobile') {
        display: none;
    }

    &:hover {
        cursor: pointer;
        background-color: $dark10;
    }

    img {
        width: 22px;
    }
}

.reset {
    @include screen('mobile') {
        display: flex;
    }
}

@import 'src/assets/scss/mixins.scss';

.notes {
    background: $dark3;
    padding: 20px;
    border-radius: 10px;
    margin: 20px 0;
    p {
        font-size: 12px !important;
        line-height: 20px;
    }
}
.sales {
    span {
        font-weight: 900;
    }
}

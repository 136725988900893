@import 'src/assets/scss/mixins.scss';

.productUserData {
    display: flex;
    justify-content: space-between;
    border-bottom: 1px solid $dark10;
    padding-bottom: 20px;
    margin-bottom: 20px;

    .userData {
        text-align: right;
    }

    .bikeData {
        display: flex;
        flex-direction: column;
        h2 {
            flex: 1;
        }
    }
}

@import 'assets/scss/mixins';

.loaderWrapper {
    flex: 1;
    display: flex;
    justify-content: center;
    align-items: center;
}

.root {
    flex: 1;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    padding: 0 40px;
    text-align: center;

    @include screen('mobile') {
        padding-bottom: 130px;
    }

    h1 {
        margin-top: 32px;
        text-transform: uppercase;

        @include screen('mobile') {
            margin-top: 24px;
            font-size: toRem(28);
            line-height: toRem(34);
        }
    }

    .text {
        margin-top: 16px;
        color: $dark60;
        font-family: $secondary-font;
        letter-spacing: unset;
        &.error {
            color: $red150;
        }
        @include screen('mobile') {
            margin-top: 8;
            font-size: toRem(14);
        }
        b {
            font-weight: 700;
        }
    }

    .loader {
        &:after {
            border: 3px solid white;
            border-color: white transparent white transparent;
        }
    }
}

.bikeImage {
    max-height: 240px;
}

.button {
    margin-top: 32px;

    @include screen('mobile') {
        margin-top: 24px;
        width: 100%;
    }
}

@import 'assets/scss/mixins';

.logo {
    height: 100%;
    padding: 0 30px;
    display: flex;
    align-items: center;
    background-color: $mainRed;
    position: relative;
    transition: all 150ms ease-in-out;

    @include screen('mobile') {
        padding: 20px 24px;
        display: flex;
        justify-content: center;
        align-items: center;

        img {
            max-width: 140px;
        }
    }

    &:hover {
        cursor: pointer;
        background: $red110;
    }
    &.twinner {
        background-color: $twinnerDark;
        img {
            transform: scale(1.1);
        }
    }
    .label {
        color: white;
        position: absolute;
        bottom: 0;
        left: 0;
        width: 100%;
        text-align: center;
        font-weight: 400;
        font-size: 12px;
    }
}

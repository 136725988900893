@import 'assets/scss/mixins';

.contactButton {
    @include screen('mobile') {
        i {
            margin-right: 0 !important;
        }
    }
}
.modal {
    padding: 0;
    @include scrollbar(4px, $dark20, transparent);

    i {
        color: $dark140;
    }
}

@import 'assets/scss/mixins';

.modal {
    min-height: 400px;
    display: flex;
    justify-content: center;
    align-items: center;

    h3 {
        margin-bottom: 8px;
    }

    p {
        font-size: toRem(14);
        line-height: toRem(22);
        color: $dark60;
        margin-bottom: 16px;
    }

    button {
        width: 100%;
    }
    .link {
        font-weight: 800;
    }

    .codeWrapper {
        background-color: $dark5;
        border-radius: 4px;
        padding: 24px;
        text-align: center;
        margin-bottom: 24px;
        position: relative;

        .code {
            font-size: toRem(24);
            line-height: toRem(30);
            font-weight: 500;
            margin-bottom: 8px;
            font-variant-numeric: tabular-nums;
        }

        .link {
            color: $dark60;
            font-size: toRem(14);
            font-family: $secondary-font;

            span {
                color: $dark1;
                font-variant-numeric: tabular-nums;
            }
        }

        .copy_icon {
            position: absolute;
            right: 12px;
            top: 12px;
            cursor: pointer;
            color: $dark140;
        }
    }

    .cashback {
        font: 400 toRem(14) / toRem(20) Mulish, sans-serif;
        color: $dark60;
        margin-bottom: 16px;
    }

    .email_wrapper {
        margin-bottom: 24px;
    }

    .buttonWrapper {
        position: relative;
    }

    .checkbox_container {
        display: flex;
        align-items: center;
        gap: 12px;
        margin-top: 12px;
        .checkbox_text {
            height: fit-content;
            color: $dark60;
            font: 14px/16px Mulish, sans-serif;
            margin: 0;
        }
    }

    .successMessage {
        background-color: $mainGreen;
        color: $mainWhite;
        width: auto;
        font-size: toRem(14);
        line-height: toRem(22);
        font-family: $secondary-font;
        border-radius: 4px;
        display: inline-block;
        padding: 8px 16px;

        position: absolute;
        left: 50%;
        transform: translate(-50%, 0);
        opacity: 0;
        transition: all 300ms ease-in-out;

        i {
            margin-right: 8px;
            font-size: 20px;
        }

        &.show {
            transform: translate(-50%, -43px);
            opacity: 1;
        }
    }
}

@include screen('mobile') {
    .copyButton i {
        margin-right: 0 !important;
    }
}

@import 'assets/scss/mixins';

.button {
    @include screen('mobile') {
        i {
            margin-right: 0 !important;
        }
    }
}

.text {
    @include screen('mobile') {
        display: none;
    }
}

.title {
    text-transform: uppercase;
    margin-bottom: 8px;
}

.subtitle {
    color: $dark60;
    font-size: toRem(14);
    line-height: toRem(22);
    margin-bottom: 24px;
}

.emailsWrapper {
    max-height: 200px;
    overflow-y: auto;
    // margin-bottom: 12px;
    display: flex;
    flex-wrap: wrap;
    column-gap: 4px;
    row-gap: 12px;
}

.inputSuffix {
    color: $mainGreen;
    cursor: pointer;
}

.errorMessage {
    color: $mainWarring;
    font-size: toRem(14);
    line-height: toRem(22);
}

.modalButton {
    margin-top: 24px;
    width: 100%;
}
.inputsRow {
    display: flex;
    gap: 8px;
    margin-bottom: 12px;

    .input {
        flex: 1;
        min-width: none;
    }
}
.addButton {
    height: 56px;
    font-size: 20px;
}
.formLabel {
    margin-bottom: 6px;
    display: block;
}

@import 'assets/scss/mixins';

$loader-size: 48px;
$button-offset: 4px;
$button-size: 40px;

.root {
    position: absolute;
    z-index: 2;
    top: 28px;
    right: 28px;
}

.buttonContainer {
    position: relative;
    width: $loader-size;
    height: $loader-size;
}

.circularLoader {
    position: absolute;
    top: 0;
    left: 0;
    width: $loader-size;
    height: $loader-size;
    transform: rotate(-90deg);
    pointer-events: none;
}

.loaderTrack {
    fill: none;
    stroke: #cccccc;
    stroke-width: 4;
}

.loaderProgress {
    fill: none;
    stroke: $mainRed;
    stroke-width: 4;
    stroke-linecap: round;
}

.control3d {
    position: absolute;
    z-index: 2;
    top: $button-offset;
    left: $button-offset;

    cursor: not-allowed;
    width: $button-size;
    height: $button-size;
    background-color: $dark5;
    border-radius: 50%;
    border: 1px solid $dark10;
    display: flex;
    align-items: center;
    justify-content: center;
    transition: all 150ms ease-in;

    &:hover {
        background-color: #e7e6ea;
    }
}

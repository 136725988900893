@import 'assets/scss/mixins';

.loader {
    width: auto;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;

    h6 {
        margin-top: 16px;
    }
}

.something_went_wrong {
    @extend .loader;
    padding: 0 32px;
    text-align: center;
}

.configurator {
    display: flex;
    justify-content: space-between;
    max-height: calc(100vh - 80px);
    flex: 1;

    .blurLayer {
        top: 0;
        left: 0;
        bottom: 0;
        right: 0;
        z-index: 10;
        position: absolute;
        background: transparent;
        pointer-events: none;
        transition: all 350ms ease-in;

        &.active {
            backdrop-filter: blur(5px);
            -webkit-backdrop-filter: blur(5px);
            background: rgba(255, 255, 255, 0.2);
            backdrop-filter: blur(5px);
            -webkit-backdrop-filter: blur(5px);
            pointer-events: all;
        }
    }
    @include screen('large-tablet') {
        height: auto;
        // min-height: calc(100vh - 80px);
        flex-direction: column;
    }

    @include screen('mobile') {
        // min-height: calc(100vh - 56px);
        max-height: unset;
    }
}

.main_container {
    z-index: 9;
    padding: 0 0 32px;
    flex-grow: 1;
    display: flex;
    flex-direction: column;
    overflow-x: hidden;
    position: relative;
    @include screen('large-tablet') {
        padding: 0 0 32px;
    }

    @include screen('mobile') {
        padding: 16px;
        // min-height: 380px;
    }
}

.weight_wrapper {
    display: flex;
    gap: 6px;
    margin-top: 8px;

    @include screen('mobile') {
        margin-top: 2px;
    }

    .weight_text {
        color: $dark60;
    }
}

.configuratorView {
    position: relative;
    flex-grow: 1;
    overflow-y: hidden;
    border: 1px solid $dark10;
    border-top: none;
    background-color: $dark3;

    .configuratorPart {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        object-fit: contain;
    }
}

.image {
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;

    h1 {
        margin-bottom: 62px;
        text-transform: uppercase;
        align-self: flex-start;

        @include screen('large-tablet') {
            margin-bottom: 30px;
        }

        @include screen('mobile') {
            margin-bottom: 24px;
        }
    }

    img {
        width: 100%;
        object-fit: contain;
        // 445px - because on every screen size image is separated from top and bottom point 225px
        max-height: calc(100vh - 445px);

        @include screen('2k') {
            max-height: calc(100vh - 452px);
        }
        @include screen('large-desktop') {
            max-height: calc(100vh - 456px);
        }
        @include screen('large-tablet') {
            max-height: 730px;
        }

        @include screen('tablet') {
            max-height: 412px;
        }

        @include screen('small-tablet') {
            max-height: 368px;
        }
    }
}

.leave_modal {
    display: flex;
    flex-direction: column;

    .heading {
        display: flex;
        flex-direction: column;
        gap: 8px;
    }

    .title {
        letter-spacing: 0.03em;
        text-transform: uppercase;
    }

    .subtitle {
        color: $dark60;
        letter-spacing: 0.01em;
    }
}

.wrap3d_container {
    flex-grow: 1;
    height: 0;
    width: 100%;
    display: flex;
    flex-direction: column;
    position: relative;

    @include screen('mobile') {
        margin: -16px -16px 0;
        width: auto;
    }
}

.weight_container {
    position: absolute;
    top: 32px;
    left: 40px;
    z-index: 5;

    .bike_name {
        text-transform: uppercase;
        color: $dark60;
        letter-spacing: 0.03em;
        mix-blend-mode: difference; // I don't know what is this, I just saw it in design
    }

    @include screen('mobile') {
        top: 16px;
        left: 16px;
    }
}

#bikeCanvasId {
    height: 100%;
}

@media screen and (max-height: 499px) and (orientation: landscape) {
    .wrap3d_container {
        position: fixed;
        z-index: 100;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
    }
    .weight_container {
        display: none;
    }
}

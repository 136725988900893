@import 'src/assets/scss/mixins.scss';

.tableWrapper {
    margin-top: 24px;
    table {
        width: 100%;
        font-family: $secondary-font;
        font-size: 14px;
    }
    th {
        font-size: 20px;
        font-weight: 700;
    }
    th,
    td {
        text-align: left;
        padding: 8px;
        line-height: 1.75rem;
    }
    tr:nth-child(even) {
        background-color: $dark3;
    }
}
.prices {
    margin-top: 16px;
    font-size: 16px;
    font-family: $secondary-font;
}
.discount {
    display: flex;
    gap: 16px;
    justify-content: flex-end;
    .name {
        font-weight: 700;
        font-family: $primary-font;
    }
}
